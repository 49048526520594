<template>
  <nav class="collapse navbar-collapse justify-content-end">
    <ul class="nav navbar-nav">
      <!-- bookmark folder -->
      <bookmark-folder-dropdown v-if="$currentUser" />

      <!-- alerts -->
      <alerts-dropdown v-if="$currentUser" />

      <!-- notifications -->
      <notification-dropdown v-if="$currentUser" />

      <support-dropdown />

      <get-started-dropdown />

      <invite-dropdown v-if="$can('inviteToAL')" />

      <!-- render profile dropdown -->
      <profile-dropdown
        v-if="$currentUser"
        :researchGroups="researchGroups"
      />

      <!-- user is not logged in -->
      <li v-if="!$currentUser" class="nav-item">
        <span class="nav-link t-white">
          <router-link :to="{name: 'login'}" class="t-white mr-2">Log in</router-link><span class="mr-2">|</span><router-link :to="{name: 'signup'}" class="t-white">Sign up</router-link>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import AlertsDropdown from '@/components/shared/header/dropdown/alerts/Alerts'
import BookmarkFolderDropdown from '@/components/shared/header/dropdown/Bookmarks'
import NotificationDropdown from '@/components/shared/header/dropdown/Notification'
import ProfileDropdown from '@/components/shared/header/dropdown/Profile'
import SupportDropdown from '@/components/shared/header/dropdown/Support'
import GetStartedDropdown from '@/components/shared/header/dropdown/GetStarted'
import InviteDropdown from '@/components/shared/header/dropdown/Invite'

export default {
  name: 'navBar',
  components: {
    ProfileDropdown,
    NotificationDropdown,
    SupportDropdown,
    BookmarkFolderDropdown,
    AlertsDropdown,
    GetStartedDropdown,
    InviteDropdown
  },
  data () {
    return {
      researchGroups: []
    }
  },
  created () {
    if (this.$currentUser) {
      this.researchGroups = this.$currentUser.research_groups
    }

    this.$events.$on('navbar:research_group:update:research_groups', (researchGroups) => { this.researchGroups = researchGroups })
  },
  destroyed () {
    this.$events.$off('navbar:research_group:update:research_groups')
  }
}
</script>
