<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.full_title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <h1 class="mb-3 t-700 t-xxlr mb-4" v-html="data.highlights.full_title"></h1>

        <p v-if="data.official_title" class="mb-4">
          <span class="t-600">Official Title: </span>{{ data.official_title }}
        </p>

        <div v-if="!isEmpty(data.diseases)" class="mb-4">
          <h3 class="mb-2 t-500">Conditions/Diseases:</h3>
          <keywords
            :keywords="data.diseases"
            :limit="5"
            property="term">
          </keywords>
        </div>

        <div v-if="!isEmpty(data.interventions)" class="mb-4">
          <h3 class="mb-2 t-500">Interventions:</h3>
          <keywords class="mb-4"
            :keywords="data.interventions"
            :limit="5"
            property="name"
            labelProperty="type"
            tooltipProperty='description'>
          </keywords>
        </div>

        <!-- register -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Register:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.register ? data.register : '-'}}</div>
        </div>
        <!-- trial id -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Trial ID:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.trial_id ? data.trial_id : '-'}}</div>
        </div>
        <!-- phases -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">{{'Phase' | pluralize(data.phases && data.phases.length)}}:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="!isEmpty(data.phases)">{{data.phases | separateByComma('phase')}}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- status -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Status:</div>
          <div class="col-6 col-md-7 col-xl-8 capitalize">{{data.status ? data.status : '-'}}</div>
        </div>
        <!-- expanded access status -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Expanded Access Status:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.expanded_access_status ? data.expanded_access_status : '-'}}</div>
        </div>
        <!-- study_type -->
        <div class="row mb-4">
          <div class="col-6 col-md-5 col-xl-4 t-600">Study Type:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.study_type ? data.study_type : '-'}}</div>
        </div>
        <!-- start date -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Start Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.start_date">{{ data.start_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- primary completion date -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Primary Completion Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.primary_completion_date">{{ data.primary_completion_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- study completion date -->
        <div class="row mb-4">
          <div class="col-6 col-md-5 col-xl-4 t-600">Study Completion Date:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="data.completion_date">{{ data.completion_date | formatDate('MMMM D, YYYY') }}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- age group -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Age Group:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="!isEmpty(data.population_ages)" class="capitalize">{{ data.population_ages | separateByComma('population_age')}}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- min age -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Min Age:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.minimum_age ? data.minimum_age : '-'}}</div>
        </div>
        <!-- max age -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">Max Age:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.maximum_age ? data.maximum_age : '-'}}</div>
        </div>
        <!-- Gender -->
        <div class="row">
          <div class="col-6 col-md-5 col-xl-4 t-600">{{'Gender' | pluralize(data.genders && data.genders.length)}}:</div>
          <div class="col-6 col-md-7 col-xl-8">
            <span v-if="!isEmpty(data.genders)">{{data.genders | separateByComma('name')}}</span>
            <span v-else>-</span>
          </div>
        </div>
        <!-- enrollment size -->
        <div class="row mb-4">
          <div class="col-6 col-md-5 col-xl-4 t-600">Enrollment Size:</div>
          <div class="col-6 col-md-7 col-xl-8">{{data.enrollment_count ? data.enrollment_count : '-'}}</div>
        </div>

        <long-text-collapsible
          v-if="data.highlights.eligibility_criteria"
          title="Inclusion and Exclusion criteria:"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.eligibility_criteria"
          class="mb-4 html-pre-wrap"
        />

        <long-text-collapsible
          v-if="data.highlights.primary_objective"
          title="Primary objective:"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.primary_objective"
          class="mb-4 html-pre-wrap"
        />

        <long-text-collapsible
          v-if="data.highlights.secondary_objective"
          title="Secondary objective:"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.secondary_objective"
          class="mb-4 html-pre-wrap"
        />

        <!-- locations array -->
        <div v-if="!isEmpty(locations)" class="mb-3 mt-5 d-flex">
          <h3 class="t-600 mr-2 t-regular">{{ 'Location' | pluralize(locations.length) }}:</h3>
          <ul class="list-unstyled">
            <li v-for="(location, index) in locations" :key="index">
              <template v-if="location.organisation && !isEmpty(location.organisation.slug_values)">
                <router-link
                  class="capitalize"
                  :to="{ name: 'organisation.show', params: { id: location.organisation.slug_values[0] }}"
                  v-html="location.organisation.name"
                />,
              </template>
              <span class="capitalize" v-else-if="location.organisation && location.organisation.name">{{ location.organisation.name }}, </span>
              <span v-html="renderLocationAsString(location)" />
              <flag :title="renderCountryFlagTooltip(location.country)"
                v-if="location.country && location.country.alpha_2"
                :iso="location.country.alpha_2 | lowercase"
                :squared="false"
                class="bd-radius ml-1 t-xs" />
            </li>
          </ul>
        </div>
        <!-- more information -->
        <div v-if="!isEmpty(data.urls)" class="mb-3 d-flex">
          <h3 class="t-600 mr-2 t-regular">More information:</h3>
          <ul class="list-unstyled">
            <li v-for="(url, index) in data.urls" :key="index">
              <a :href="url | formatWebsite" target="_blank">{{ url }}</a>
            </li>
          </ul>
        </div>
      </template>

      <template #modal-sidebar>
        <organisation-summary v-if="!isEmpty(data.sponsors)"
          :organisations="data.sponsors"
          :title="'Sponsors'"
          class="mt-3">
        </organisation-summary>

        <organisation-summary v-if="!isEmpty(data.highlights.collaborators)"
          :organisations="data.highlights.collaborators"
          :title="'Collaborators'"
          class="mt-3">
        </organisation-summary>

        <researcher-summary v-if="!isEmpty(data.highlights.investigators)"
          :researchers="data.highlights.investigators"
          :title="'Investigators'"
          class="mt-3">
        </researcher-summary>

        <researcher-summary v-if="!isEmpty(data.contacts)"
          :researchers="data.contacts"
          :title="'Contacts'"
          class="mt-3">
        </researcher-summary>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import ClinicalTrialService from '@/services/clinical_trial'
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import Keywords from '@/components/shared/Keywords'
import ResearcherSummary from '@/components/shared/ResearcherSummary'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import OrganisationSummary from '@/components/shared/OrganisationSummary'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'

export default {
  name: 'modalClinicalTrial',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    Keywords,
    ResearcherSummary,
    BookmarkFolder,
    LongTextCollapsible,
    OrganisationSummary,
    Identifiers,
    Share
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['full_title', 'medical_condition', 'primary_objective', 'secondary_objective', 'eligibility_criteria'],
        arrays: ['keywords.keyword'],
        researchers: ['investigators.initial_name.normalized', 'investigators.name.normalized', 'collaborators.name.normalized']
      }
    }
  },
  created () {
    this.scope = 'clinical_trial'
  },
  computed: {
    locations () {
      if (!this.data.locations) return []

      // Check if locations array has 1 or more organisations
      const hasOrganisations = !!this.data.locations.find(location => {
        return location.organisations && !this.isEmpty(location.organisations)
      })

      // If there are no organisations, simply return an array with all countries
      if (!hasOrganisations) {
        return this.data.locations.filter(location => {
          return location.country
        })
      }

      // If there are organisations, map data to structure used in render
      const locations = this.data.locations.reduce((locationsArray, location) => {
        if (location.organisations) {
          location.organisations.forEach(organisation => {
            locationsArray.push({
              // use country form organisation, fall back to more generic country on 'location'
              country: organisation.country || location.country,
              organisation: organisation
            })
          })
        }

        return locationsArray
      }, [])
      return locations
    }
  },
  methods: {
    fetch (slug) {
      ClinicalTrialService.getByID(slug).then(clinicalTrial => {
        this.data = clinicalTrial
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    renderLocationAsString (location) {
      const city = location.organisation && location.organisation.city
      const state = location.organisation && location.organisation.state

      const hasCityOrState = !!(city || state)
      let element = ''
      if (city) element += city
      if (state) element += ` ${state}`
      if (hasCityOrState) element += `, `
      if (location.country) element += location.country.name
      return element
    }
  }
}
</script>
